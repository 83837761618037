import { Action } from "../actions";
import { ActionTypes } from "../action-types";

interface IState {
  name?: string;
}

const initialState: IState = {
  name: "",
};

const reducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case ActionTypes.SET_NAME:
      return {
        ...state,
        name: action.payload.name,
      };
    default:
      return state;
  }
};

export default reducer;

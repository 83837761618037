import React, { ReactNode } from "react";
import "../../styles/Utils/Modal.css";

interface ModalType {
  children?: ReactNode;
  isOpen: boolean;
  toggle: () => void;
}

const handleClickOutside = (e: any, toggle: any) => {
  const modalBox = document.querySelector(".modal-box");
  if (modalBox && !modalBox.contains(e.target)) {
    toggle();
  }
};

const Modal = (props: ModalType) => {
  return (
    <>
      {props.isOpen && (
        <div
          className="modal-overlay"
          onClick={(e) => handleClickOutside(e, props.toggle)}
        >
          <div className="modal-box">{props.children}</div>
        </div>
      )}
    </>
  );
};

export default Modal;

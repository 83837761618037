import { combineReducers } from "@reduxjs/toolkit";
import LoggedInReducer from "./LoggedInReducer";
import UserInfoReducer from "./UserInfoReducer";
import loginModal from "./LoginModal";

const reducers = combineReducers({
  loggedInUser: LoggedInReducer,
  userInfo: UserInfoReducer,
  loginModal: loginModal,
});

export default reducers;
export type State = ReturnType<typeof reducers>;

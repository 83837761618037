import React, { useEffect, useState } from "react";

import "../../../styles/HomePage/static/FinalSlogan.css";

const FinalSlogan: React.FC = () => {
  const [textContent, setTextContent] = useState("");
  const [ImageSrc, setImageSrc] = useState("");

  useEffect(() => {
    console.log("Landing Page");
    fetch("/config.json")
      .then((response) => response.json())
      .then((data) => {
        setTextContent(data.homePage.finalSlogan.text);
        setImageSrc(data.homePage.finalSlogan.image.src);
      }
    );
  }, []);

  return (
    <div className="home-page__final-slogan__container">
      <div className="home-page__final-slogan__text__container">
        <img
          className="home-page__final-slogan__text__image"
          src={ImageSrc}  
          alt="Logo" 
        />
      </div>
      {/* <h1 className="home-page__final-slogan__title">
        {textContent}
      </h1> */}
    </div>
  );
};

export default FinalSlogan;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";

// import Pool from "./utils/UserPool";
import "../../styles/LoginModal/ForgotPassword.css";

// Props interface
interface Props {
  setStage: React.Dispatch<React.SetStateAction<number>>;
}

const ForgotPassword: React.FC<Props> = (props: Props) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>("");
  const [stage, setStage] = useState<number>(1); // 1 = email stage, 2 = code stage, 3 = password stage
  const [code, setCode] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  // handle return to Login button
  const handleReturnToLogin = (): void => {
    props.setStage(1);
  };

  // handle send email button
  const handleSendEmail = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    console.log(`Send Email to ${email}`);
    try {
      const response = await Auth.forgotPassword(email)
      console.log(response)
      setStage(2);
    } catch (error: any) {
      const errMessage = error.message || "An error occurred. Please try again.";
      console.error(errMessage);
      setErrorMessage(errMessage);
    }  
  };

  // handle reset password button
  const resetPassword = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();

    if (password !== confirmPassword) {
      console.error("Passwords are not the same");
      setErrorMessage("Passwords are not the same");
      return;
    }

    try{
      await Auth.forgotPasswordSubmit(email, code, password)
      setStage(3);
    } catch (error: any) {
      const errMessage = error.message || "An error occurred. Please try again.";
      console.error(errMessage);
      setErrorMessage(errMessage);
    }
  };

  return (
    <div className="login-modal__forgot-password__container">
      {stage === 1 && (
        <div>
          <h1>Recuperando tú contraseña</h1>
          {errorMessage && (
            <div className="alert alert-danger" role="alert">
              {errorMessage}
            </div>
          )}
          <p>
          Ingrese su dirección de correo electrónico y le enviaremos un código para restablecer su contraseña.
          </p>
          <form onSubmit={handleSendEmail}>
            <div className="form-group">
              <input
                type="email"
                className="form-control"
                id="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <br/>
              <button
                type="submit"
                className="btn btn-primary login-modal__forgot-password__button"
              >
                Enviar código de verificación
              </button>
            </div>
          </form>
        </div>
      )}
      {stage === 2 && (
        <div>
          <h1>Solicitud recibida</h1>
          {errorMessage && (
            <div className="alert alert-danger" role="alert">
              {errorMessage}
            </div>
          )}

          <p>
            Hemos recibido su solicitud para restablecer su contraseña. Verifique la bandeja de entrada de su correo electrónico ({email}) para obtener el código necesario para restablecer su contraseña.
          </p>

          <p>
          Si no recibe un correo electrónico en unos minutos, verifique su carpeta de correo no deseado (spam).
          </p>

          <form onSubmit={resetPassword}>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                id="code"
                placeholder="Codigo de verificación"
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
              <br/>
              <input
                type="password"
                className="form-control"
                id="password"
                placeholder="Nueva Contraseña"
                value={password}
                onChange={(e) => setPassword(e.target.value)} 
              />
              <br/>
              <input
                type="password"
                className="form-control"
                id="confirmPassword"
                placeholder="Confirma Nueva Contraseña"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <br/>
              <button
                type="submit"
                className="btn btn-primary  login-modal__forgot-password__button"
              >
                Restablecer Contraseña
              </button>
            </div>
          </form>
        </div>
      )}
      {stage === 3 && (
        <div>
          <h1>Restablecimiento de contraseña exitosa</h1>
          <p>
            Su contraseña ha sido restablecida con éxito. Ahora puede iniciar sesión con su nueva contraseña.
          </p>
          <button
            className="btn btn-primary"
            onClick={handleReturnToLogin}
          >
            Iniciar Sesión
          </button>
        </div>
      )}
    </div>
  );
};

export default ForgotPassword;

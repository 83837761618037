import React from "react";
import "../../../styles/HomePage/helpers/carousel.css";
import { useNavigate } from "react-router-dom";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Keyboard, Navigation } from "swiper/modules";

const slideStyle = {
  width: "100%",
  height: "100%",
};

interface IProject {
  images: {
    id: number;
    color: string;
    imgSrc: string;
    commune: string;
    street: string;
  }[];
  activateLink: boolean;
}

const Carousel: React.FC<IProject> = ({ images, activateLink }) => {
  const navigate = useNavigate();

  const roundImageCorners = (target: HTMLElement) => {
    const width = target.offsetWidth;
    const style = `border-radius: ${width}px ${width}px 0px 0px;`;
    target.setAttribute("style", style);
  };

  const handleOpenProject = (id: number) => {
    // console.log(id);
    if (activateLink) {
      navigate(`/product/${id}`);
    }
  };

  return (
    <Swiper
      loop={true}
      keyboard={{
        enabled: true,
      }}
      modules={[Autoplay, Keyboard, Navigation]}
      className="home-page__projects__swiper"
      navigation={true}
      breakpoints={{
        0: {
          slidesPerView: 1,
          navigation: {
            enabled: false,
          },
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 2,
          navigation: {
            enabled: false,
          },
          spaceBetween: 20,
        },
        992: {
          navigation: {
            enabled: true,
          },
          slidesPerView: 5,
          spaceBetween: 20,
        },
      }}
      autoplay={{
        delay: 3000,
        disableOnInteraction: false,
      }}
    >
      {images.map((row) => {
        return (
          <SwiperSlide key={row.id}>
            <div
              style={{ ...slideStyle }}
              className="home-page__projects__swiper__image"
            >
              <img
                onLoad={(e) => roundImageCorners(e.target as HTMLElement)}
                src={row.imgSrc}
                alt="Project"
                loading="lazy"
                onClick={() => {
                  handleOpenProject(row.id);
                }}
              />
            </div>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default Carousel;

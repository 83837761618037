import React, { useEffect, useState } from "react";

import "../../styles/HomePage/LandingPage.css";

const LandingPage: React.FC = () => {
  const [landingPageInfo, setLandingPageInfo] = useState({
    title: "",
    button: "",
    imageSrc: "",
  });

  useEffect(() => {
    console.log("Landing Page");
    fetch("/config.json")
      .then((response) => response.json())
      .then((data) => {
        setLandingPageInfo({
          title: data.homePage.landingPage.title,
          button: data.homePage.landingPage.button,
          imageSrc: data.homePage.landingPage.image.src,
        });
      });
  }, []);

  const scrollToInfo = () => {
    if (window.innerWidth < 991) {
      const navbar = document.querySelector(".navbar");
      const navbarHeight = navbar ? navbar.clientHeight : 0;
      window.scrollTo({
        top: window.innerHeight - navbarHeight,
        behavior: "smooth",
      });
    } else {
      window.scrollTo({
        top: window.innerHeight,
        behavior: "smooth",
      });
    }
  };

  return (
    // <!-- Home Page -->
    <section className="home-page__landing-page__container show" id="home">
      <div className="home-page__landing-page__central-content">
        <div className="home-page__landing-page__central-content__image">
          <img src={landingPageInfo.imageSrc} alt="Logo" />
        </div>
        <div className="home-page__landing-page__central-content__text">
          <h1>
            <b>{landingPageInfo.title}</b>
          </h1>
        </div>
        <div className="home-page__landing-page__central-content__button">
          <button onClick={scrollToInfo}>{landingPageInfo.button}</button>
        </div>
      </div>
    </section>
  );
};

export default LandingPage;

import React, { useEffect, useState } from "react";

import "../../../styles/HomePage/static/ProductDescription.css";

const ProductDescription: React.FC = () => {
  const [textContent, setTextContent] = useState({
    title: "",
    content: [],
    description: [{ text: "", image_link: "" }],
  });
  const [width, setWidth] = useState<number>(window.innerWidth);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    console.log("Landing Page");
    fetch("/config.json")
      .then((response) => response.json())
      .then((data) => {
        setTextContent({
          title: data.homePage.productDescription.title,
          content: data.homePage.productDescription.content,
          description: data.homePage.productDescription.description,
        });
        console.log(data.homePage.productDescription.content);
      });
  }, []);

  const formatParagraph = (paragraph: string, class_name: string) => {
    if (width < 600) {
      return [<p>{paragraph.replaceAll("\n", " ")}</p>];
    }
    return paragraph.split("\n").map((line: string, key: number) => {
      return (
        <p key={key} className={class_name}>
          {line}
        </p>
      );
    });
  };
  return (
    <div className="home-page__product-description__container">
      <div className="home-page__product-description__text-container">
        <h1>
          <img
            src="/images/HomePage/arrow.png"
            alt=""
            className="home-page__product-description__text-arrow"
          />
          {textContent.title}
        </h1>
        {textContent.content.map((paragraph: string, key: number) => {
          return (
            <>
              <p className="home-page__product-description__text-text">
                {paragraph}
              </p>
              <p/>
            </>
          );
        })}
      </div>
      <div className="home-page__product-description__ls-container">
        {textContent.description.map(({ text, image_link }, key: number) => {
          return (
            <div
              className="home-page__product-description__individual-container"
              id={`home-page__product-description__individual-container-${key}`}
            >
              <section>
                <div className="home-page__product-description__image-container">
                  <img
                    src={image_link}
                    alt=""
                    className="home-page__product-description__image"
                    id={`home-page__product-description__image-${key}`}
                  />
                </div>
                <div
                  className="home-page__product-description__paragraph"
                  id={`home-page__product-description__paragraph-${key}`}
                >
                  <p className="home-page__product-description__paragraph-text">
                    {text}
                  </p>
                </div>
              </section>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ProductDescription;

import React, { useEffect, useState } from "react";

import "../../styles/ProductPage/index.css";
import ProductsCarousel from "./ProductsCarousel";
import { useParams } from "react-router-dom";
import Filter from "./Filter";

import { useSelector } from "react-redux";
import { State } from "../../state";

const adminUsers = ["f0a0d652-a827-4d23-b3ba-737cae9e43dc"]

const ProductPage: React.FC = () => {
  const { productId } = useParams();
  const [imageLink, setImageLink] = useState("");
  const loggedInState = useSelector((state: State) => state.loggedInUser);
  const [blur, setBlur] = useState(true);

  useEffect(() => {
    fetch("/config.json")
      .then((response) => response.json())
      .then((data) => {
        setImageLink(data.ProductPage.Index.ComingSoon.ImageLink.src);
      });
  }, []);

  useEffect(() => {
    console.log("projectId: ", productId);
  }, [productId]);

  useEffect(() => {
    const user = loggedInState.user;
    let username = "";
    if (user && 'username' in user && typeof user.username === 'string') {
      username = user.username;
    }
    console.log("username: ", username)
    if (loggedInState.loggedIn && adminUsers.includes(username)) {
      setBlur(false);
    }
  }, [loggedInState]);

  return (
    <div>
      {blur && (
        <div className="product-page__coming-soon-image-container">
          <img src={imageLink} alt="coming soon" />
        </div>
      )}
      <div className={`section product-page__container ${blur && "product-page__blur"}`}>
        <Filter />
        <div className="product-page__sub-container">
          <ProductsCarousel productId={productId} />
          {/* <GetCreditScore /> */}
        </div>
      </div>
    </div>
  );
};

export default ProductPage;

import React from "react";

import "../../styles/LoadingPage/index.css";

interface Props {
  baseColor?: boolean;
}

const LoadingPage: React.FC<Props> = (props) => {
  return (
    <div className={`loading-page_container ${props.baseColor && "loading-page_base-color"}`}>
      <div className="loading-page_spinner"></div>
    </div>
  );
};

export default LoadingPage;
import React, { useState } from "react";
import "../../../styles/ProductPage/Filters/Price.css";

const Price: React.FC = () => {
  const [minPrice, setMinPrice] = useState<number>(0);
  const [maxPrice, setMaxPrice] = useState<number>(0);

  return (
    <div className="product-page__filter-price__container">
      <div
        className="input-group input-group-sm mb-3"
        style={{ width: "8rem" }}
      >
        <span className="input-group-text" id="inputGroup-sizing-sm">
          Min
        </span>
        <input
          type="number"
          placeholder="Min"
          className="form-control"
          aria-label="Sizing example input"
          aria-describedby="inputGroup-sizing-sm"
          value={maxPrice}
          onChange={(e) => setMinPrice(parseInt(e.target.value))}
        />
      </div>
      <div
        className="input-group input-group-sm mb-3"
        style={{ width: "8rem" }}
      >
        <span className="input-group-text" id="inputGroup-sizing-sm">
          Max
        </span>
        <input
          type="number"
          placeholder="Min"
          className="form-control"
          aria-label="Sizing example input"
          aria-describedby="inputGroup-sizing-sm"
          value={maxPrice}
          onChange={(e) => setMinPrice(parseInt(e.target.value))}
        />
      </div>
    </div>
  );
};

export default Price;

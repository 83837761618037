import React, { useEffect, useState } from "react";
import "../../styles/ProductPage/ProductInfo.css";

type ProductInfoProps = {
  color: string;
};

const ProductInfo: React.FC<ProductInfoProps> = (props) => {
  const [imageLink, setImageLink] = useState("");

  useEffect(() => {
    fetch("/config.json")
      .then((response) => response.json())
      .then((data) => {
        setImageLink(data.ProductPage.ProductInfo.ImageLink.src);
      });
  }, []);
  return (
    <div className="product-page__product-info__container">
      <table className="product-page__product-info__table">
        <tr className="product-page__product-info__table-title">
          <th colSpan={2}>
            <img
              className="product-page__product-info__table-title__image"
              src={imageLink}
              alt="Logo"
            />
            <p>Departamento 216B</p>
          </th>
        </tr>
        <tr className="product-page__product-info__table-subtitle table__add-bottom-line">
          <th colSpan={2}>Hogar</th>
        </tr>
        <tr>
          <th>Tipología</th>
          <td>Estudio</td>
        </tr>
        <tr>
          <th>Superficie total</th>
          <td>28,30 m²</td>
        </tr>
        <tr>
          <th>Orientación</th>
          <td>Sur</td>
        </tr>
        <tr>
          <th>Piso</th>
          <td>2</td>
        </tr>
        <tr>
          <th>Color</th>
          <td>{props.color}</td>
        </tr>
        <tr className="product-page__product-info__table-subtitle table__add-bottom-line">
          <th colSpan={2}>Financiamiento</th>
        </tr>
        <tr>
          <th>Precio</th>
          <td>2000 UF</td>
        </tr>
        <tr>
          <th>Pie</th>
          <td>100 UF</td>
        </tr>
        <tr>
          <th>Nº cuotas</th>
          <td>0</td>
        </tr>
        <tr>
          <th>Tipo de crédito</th>
          <td>-</td>
        </tr>
        <tr className="product-page__product-info__table-subtitle table__add-bottom-line">
          <th colSpan={2}>Cuota</th>
        </tr>
        <tr>
          <th>Valor Cuota</th>
          <td>12 UF</td>
        </tr>
      </table>
    </div>
  );
};

export default ProductInfo;

import React, { useEffect, useState } from "react";
import Product from "./Product";
import LoadingPage from "../LoadingPage";
import styled from "styled-components";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { useParams } from "react-router-dom";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "../../styles/ProductPage/ProductsCarousel.css";

// import required modules
import { Keyboard, Navigation } from "swiper/modules";

// props (productId optional)
interface Props {
  productId?: string;
}

interface Slide {
  id: string;
  props?: any;
}

const ProductsCarousel: React.FC<Props> = ({ productId }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [slides, setSlides] = useState<Slide[]>([]);
  const [slidesCount, setSlidesCount] = useState(0);

  useEffect(() => {
    // Initialize the carousel with the first set of items
    setStartingSlides(productId);
    getSlidesCount();
  }, []);

  const getSlidesCount = () => {
    // ToDo: Get the total number of items from the database
    fetch(`${process.env.REACT_APP_BACKEND_LINK}/api/products_amount`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        filters: {},
      }),
    }).then((response: Response): any => {
      response.json().then((data) => setSlidesCount(data.amount));
    });
  };

  const setSlidesById = (
    id?: string,
    amount?: number,
    excludeFirst: boolean = false
  ) => {
    // ToDo: Get the item by id from the database
    fetch(`${process.env.REACT_APP_BACKEND_LINK}/api/products_by_id`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        product_id: {
          id: id,
        },
        amount: amount,
        excludeFirst: excludeFirst,
        filters: {},
      }),
    }).then((response: Response): any => {
      response.json().then((data) => {
        console.log(data.products);
        let newSlides = data.products.map((product: any) => {
          return {
            id: product.id,
            props: {
              name: product.name,
              color: product.color,
              images: product.images.slice(1, 6),
            },
          };
        });
        setSlides([...slides, ...newSlides]);
        setIsLoaded(true);
      });
    });
  };

  const setStartingSlides = (productId?: string) => {
    setSlidesById(productId, 5);
  };

  const handleChange = (e: any) => {
    if (e.activeIndex + 2 >= slides.length && slides.length < slidesCount) {
      let lastSlideId = slides[slides.length - 1].id;
      setSlidesById(lastSlideId, 2, true);
    }
  };

  return (
    <>
      {isLoaded ? (
        <Swiper
          slidesPerView={1}
          spaceBetween={30}
          loop={false}
          keyboard={{
            enabled: true,
          }}
          navigation={true}
          breakpoints={{
            0: {
              navigation: {
                enabled: false,
              },
            },
            992: {
              navigation: {
                enabled: true,
              },
            },
          }}
          modules={[Keyboard, Navigation]}
          className="product-page__products__swiper"
          onSlideChange={(e) => handleChange(e)}
        >
          {slides.map((slide) => (
            <SwiperSlide id={slide.id} data-hash={slide.id}>
              <Product
                id={slide.id}
                name={slide.props.name}
                color={slide.props.color}
                images={slide.props.images}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <div className="product-page__products__loading-container">
          <div className="product-page__products__loading-spacer"></div>
          <div className="product-page__products__loading">
            <LoadingPage baseColor={true} />
          </div>
        </div>
      )}
    </>
  );
};

export default ProductsCarousel;

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "@reduxjs/toolkit";
import { actionCreators, State } from "../../state";

import "../../styles/Footer/index.css";

const Footer: React.FC = () => {
  const [logoSrc, setLogoSrc] = useState("");
  const dispatch = useDispatch();
  const { openLogInModal } = bindActionCreators(
    actionCreators,
    dispatch
  );

  useEffect(() => {
    console.log("Footer");
    fetch("/config.json")
      .then((response) => response.json())
      .then((data) => {
        setLogoSrc(data.footer.logo.src);
      });
  });

  const handleHomeLink = () => {
    window.scrollTo(0, 0);
  }

  const handleLogInLink = () => {
    window.scrollTo(0, 0);
    openLogInModal();
  }

  return (
    <footer className="site-footer">
      <div className="footer__container">
        <div className="footer__grid">
          <div className="footer__column footer__logo-container">
            <img
              src={logoSrc}
              className="footer__logo-img"
              alt="Benco Logo"
            />
          </div>
          <div className="footer__column footer__internal_links_container">
            <p>
              <a onClick={handleHomeLink} className="footer__link">
                Home
              </a>
            </p>
            <p>
              <a onClick={handleLogInLink} className="footer__link">
                Iniciar Sesión
              </a>
            </p>
          </div>
          <div className="footer__column" />
          <div className="footer__column" />
          <div className="footer__column" />
          <div className="footer__column footer__links_container">
            <button
              type="button"
              className="btn btn-link btn-floating mx-1 footer__link-icon"
              onClick={() => { window.open("https://www.linkedin.com/company/benco-cl/about/", "_blank") }}
            >
              <i className="fa fa-linkedin"></i>
            </button>
          </div>
        </div>
        <div className="footer__rights">
          <p>
            © 2023 BENCO. Todos los derechos reservados
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import React, { useEffect, useState } from "react";
import Carousel from "./helpers/carousel";

import "../../styles/HomePage/Projects.css";

import { useSelector } from "react-redux";
import { State } from "../../state";

const Projects: React.FC = () => {
  const loggedInState = useSelector((state: State) => state.loggedInUser);
  const [images, setImages] = useState([] as any);

  useEffect(() => {
    console.log("Landing Page");
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
    };
    const group_size = 12;
    fetch(
      `${process.env.REACT_APP_BACKEND_LINK}/api/random_group_of_products?group_size=${group_size}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setImages(
          data.products.map((product: any) => {
            return {
              id: product.id,
              imgSrc: product.image,
              commune: product.commune,
              street: product.street,
            };
          })
        );
      });
  }, []);

  return (
    <div className="section home-page__projects-listing__container">
      <div className="home-page__projects-listing__text-container">
        <div className="home-page__projects-listing__text">
          <div className="home-page__projects-listing__title">
            <h1>Proyectos</h1>
          </div>
          <div className="home-page__projects-listing__subtitle">
            <h2>EN VENTA</h2>
          </div>
        </div>
      </div>
      <div className="home-page__projects-listing__projects-carrousel">
        <Carousel images={images} activateLink={loggedInState.loggedIn} />
      </div>
    </div>
  );
};

export default Projects;

import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import { Auth } from "aws-amplify";

const HandleLogInUser = async (
  email: string,
  password: string,
  logInUser: Function,
  setGlobalUserName: Function,
  callback: Function
): Promise<any> => {
  try {
    console.log("email: " + email);
    const user = await Auth.signIn(email, password);
    console.log(user);
    logInUser(user);

    // set user name
    setGlobalUserName(`${user.attributes.given_name} ${user.attributes.family_name}`);
    callback("SUCCESS");
  } catch (err: any) {
    let errMessage = err.message || JSON.stringify(err);
    console.log(errMessage);
    callback(errMessage);
  }
};

export default HandleLogInUser;

import React, { useEffect, useState } from "react";

import "../../../styles/HomePage/static/Intro.css";

const Intro: React.FC = () => {
  const [imageLink, setImageLink] = useState("");
  const [titleContent, setTitleContent] = useState({ content: "" });
  const [textContent, setTextContent] = useState({ content: "" });
  const [titleImageSrc, setTitleImageSrc] = useState("");

  useEffect(() => {
    console.log("Landing Page");
    fetch("/config.json")
      .then((response) => response.json())
      .then((data) => {
        setImageLink(data.homePage.image.src);
        setTextContent({
          content: data.homePage.text.content,
        });
        setTitleContent({
          content: data.homePage.text.title,
        });
        setTitleImageSrc(data.homePage.intro.image.src);
      });
  }, []);

  const handleLine = (line: string) => {
    let lineArray = line.split("®");
    return lineArray.map((word: string, key: number) => {
      // just add <sup>®</sup> in between lines, not at the end
      if (key+1 == lineArray.length) {
        return (
          <span key={key}>
            {word}
            <p/>
          </span>
        )
      }
      return (
        <span key={key}>
          {word}
          <sup>®</sup>
        </span>
      );
    });
  };
  return (
    <div className="home-page__intro-container">
      <div className="home-page__intro-container__title">
        <img
          className="home-page__intro-container__title__image"
          src={titleImageSrc}
          alt="Logo"
        />
      </div>
      <section>
        <div className="home-page__intro-container__text-container">
          <h1>
            <img
              src="/images/HomePage/arrow.png"
              alt=""
              className="home-page__text-arrow"
            />
            {titleContent.content}
          </h1>
          {textContent.content.split("\n").map((line: string, key: number) => {
            return (
              <p key={key} className="home-page__intro-container__text-text">
                {handleLine(line)}
              </p>
            );
          })}
        </div>
        <div className="home-page__intro-container__image-container">
          <img src={imageLink} className="image-container__image" alt="Logo" />
        </div>
      </section>
    </div>
  );
};

export default Intro;

import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "@reduxjs/toolkit";

import "../../styles/LoginPage/index.css";
import ConfirmAccount from "./ConfirmAccount";
import { actionCreators } from "../../state";
import HandleLogInUser from "./utils/HandleLogInUser";
import { Auth } from "aws-amplify";

const LoginPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { logInUser, setGlobalUserName } = bindActionCreators(
    actionCreators,
    dispatch
  );
  const [submitButtonClicked, setSubmitButtonClicked] =
    useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [rememberMe, serRememberMe] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [stage, setStage] = useState<number>(1); // 1: log in, 2: confirm user

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setPassword(e.target.value);
  };

  const handleRememberMeChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    serRememberMe(e.target.checked);
  };

  const handleLogin = async (): Promise<void> => {
    setSubmitButtonClicked(true);
    setErrorMessage("");

    await HandleLogInUser(
      email,
      password,
      logInUser,
      setGlobalUserName,
      async function (result: string) {
        console.log("result:" + result);
        setSubmitButtonClicked(false);
        if (result === "SUCCESS") {
          navigate("/");
        } else if (result === "User is not confirmed.") {
          console.log("handle confirm user");
          try {
            const result = await Auth.resendSignUp(email);
            console.log("result: ", result);
            setStage(2);
          } catch (error: any) {
            let errorMessage = error.message || "User is not confirmed.";
            console.log("error: ", error);
            setErrorMessage(errorMessage);
          }
        } else {
          setErrorMessage(result);
        }
      }
    );
  };

  return (
    <div className="login-container">
      <h2>Log In</h2>
      {stage === 1 && (
        <div>
          {/* <!-- Error Message --> */}
          {errorMessage && (
            <div className="error-message-container">
              <h5>{errorMessage}</h5>
            </div>
          )}
          <form>
            {/* <!-- Email input --> */}
            <div className="form-outline mb-4">
              <input
                type="email"
                id="form2Example1"
                className="form-control"
                value={email}
                onChange={handleEmailChange}
              />
              <label className="form-label" htmlFor="form2Example1">
                Email address
              </label>
            </div>

            {/* <!-- Password input --> */}
            <div className="form-outline mb-4">
              <input
                type="password"
                id="form2Example2"
                className="form-control"
                value={password}
                onChange={handlePasswordChange}
              />
              <label className="form-label" htmlFor="form2Example2">
                Password
              </label>
            </div>

            {/* <!-- 2 column grid layout for inline styling --> */}
            <div className="row mb-4">
              <div className="col d-flex justify-content-center">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="form2Example31"
                    checked={rememberMe}
                    onChange={handleRememberMeChange}
                  />
                  <label className="form-check-label" htmlFor="form2Example31">
                    {" "}
                    Remember me{" "}
                  </label>
                </div>
              </div>

              <div className="col">
                {/* <!-- Simple link --> */}
                <NavLink to="/login/forgot-password">Forgot password?</NavLink>
              </div>
            </div>

            {/* <!-- Submit button --> */}
            {submitButtonClicked ? (
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              <button
                type="button"
                className="btn btn-primary btn-block mb-4"
                onClick={handleLogin}
              >
                Sign in
              </button>
            )}

            {/* <!-- Register buttons --> */}
            <div className="text-center">
              <p>
                Not a member? &nbsp;
                <NavLink to="/login/register">Register</NavLink>
              </p>
              <p>or sign up with:</p>
              <button type="button" className="btn btn-link btn-floating mx-1">
                <i className="fa fa-facebook-f"></i>
              </button>

              <button type="button" className="btn btn-link btn-floating mx-1">
                <i className="fa fa-google"></i>
              </button>

              <button type="button" className="btn btn-link btn-floating mx-1">
                <i className="fa fa-twitter"></i>
              </button>

              <button type="button" className="btn btn-link btn-floating mx-1">
                <i className="fa fa-github"></i>
              </button>
            </div>
          </form>
        </div>
      )}
      {stage === 2 && <ConfirmAccount email={email} password={password} />}
    </div>
  );
};

export default LoginPage;

import React, { useState } from "react";

import { NavLink } from "react-router-dom";

import "../../styles/LoginPage/Register.css";
// import Pool from "./utils/UserPool";
import ConfirmAccount from "./ConfirmAccount";
// import { CognitoUserAttribute } from "amazon-cognito-identity-js";
import { Auth } from "aws-amplify";

const Register: React.FC = () => {
  const [stage, setStage] = useState<number>(1); // 1: register, 2: confirm email
  const [submitButtonClicked, setSubmitButtonClicked] =
    useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [rut, setRut] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [phone, setPhone] = useState<string>("+56");
  const [address, setAddress] = useState<string>("");
  const [birthDate, setBirthDate] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [validEmail, setValidEmail] = useState<boolean>(true);
  const [validRut, setValidRut] = useState<boolean>(true);
  const [validName, setValidName] = useState<boolean>(true);
  const [validLastName, setValidLastName] = useState<boolean>(true);
  const [validPhone, setValidPhone] = useState<boolean>(true);
  const [validAddress, setValidAddress] = useState<boolean>(true);
  const [validBirthDate, setValidBirthDate] = useState<boolean>(true);
  const [validPassword, setValidPassword] = useState<boolean>(true);
  const [passwordErrorMessages, setPasswordErrorMessages] = useState<string[]>(
    []
  );
  const [errorMessage, setErrorMessage] = useState<string>("");

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const checkEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setValidEmail(emailRegex.test(email));
    return emailRegex.test(email);
  };

  const formatRut = (rut: string): string => {
    // XX.XXX.XXX-X || X.XXX.XXX-X

    const newRut = rut
      .replace(/\./g, "")
      .replace(/\-/g, "")
      .trim()
      .toLowerCase();
    if (newRut.length === 0) {
      return "";
    }
    if (newRut.length > 9) {
      return formatRut(newRut.substr(0, 9));
    }
    const lastDigit = newRut.substr(-1, 1);
    if (lastDigit.match(/[k0-9]/) === null) {
      return formatRut(newRut.substr(0, newRut.length - 1));
    }
    const rutDigit = newRut.substr(0, newRut.length - 1).replace(/\k/, "");
    let format = "";
    for (let i = 1; i <= rutDigit.length; i++) {
      if ((i - 1) % 3 === 0 && i !== 1) {
        format = ".".concat(format);
      }
      const e = rutDigit.charAt(rutDigit.length - i);
      format = e.concat(format);
    }
    return format.concat("-").concat(lastDigit);
  };

  const handleRutChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRut(formatRut(e.target.value));
  };

  const checkRut = (rut: string): boolean => {
    // XX.XXX.XXX-X || X.XXX.XXX-X
    const rutRegex = /^(\d{1,2}(\.\d{1,3}){2}-[\dkK])$/;
    setValidRut(rut.match(rutRegex) !== null);
    return rut.match(rutRegex) !== null;
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const checkName = (name: string): boolean => {
    const nameRegex = /^[a-zA-ZÀ-ÿ\s]{1,40}$/;
    setValidName(name.match(nameRegex) !== null);
    return name.match(nameRegex) !== null;
  };

  const handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(e.target.value);
  };

  const checkLastName = (lastName: string): boolean => {
    const lastNameRegex = /^[a-zA-ZÀ-ÿ\s]{1,40}$/;
    setValidLastName(lastName.match(lastNameRegex) !== null);
    return lastName.match(lastNameRegex) !== null;
  };

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPhone(formatPhone(e.target.value));
  };

  const formatPhone = (phone: string): string => {
    // +56 9 XXXX XXXX
    const newPhone = phone.replace(/\s/g, "").replace(/\+/g, "");
    if (newPhone.length < 3) {
      return "+56";
    }
    if (newPhone.length > 11) {
      return formatPhone(newPhone.substr(0, 11));
    }
    let format = "+56 ";
    for (let i = 3; i <= newPhone.length; i++) {
      if (i % 4 === 0 && i !== 1) {
        format = format.concat(" ");
      }
      const e = newPhone.charAt(i - 1);
      format = format.concat(e);
    }
    return format;
  };

  const checkPhone = (phone: string): boolean => {
    // +56 9 XXXX XXXX || +562 XXXX XXXX
    const phoneRegex = /^(\+56\s9\s\d{4}\s\d{4})|(\+562\s\d{4}\s\d{4})$/;
    setValidPhone(phone.match(phoneRegex) !== null);
    return phone.match(phoneRegex) !== null;
  };

  const handleAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAddress(e.target.value);
  };

  const checkAddress = (address: string): boolean => {
    const addressRegex = /^[a-zA-ZÀ-ÿ0-9\s]{1,40}$/;
    setValidAddress(address.match(addressRegex) !== null);
    return address.match(addressRegex) !== null;
  };

  const handleBirthDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBirthDate(e.target.value);
  };

  const checkBirthDate = (birthDate: string): boolean => {
    const birthDateRegex = /^\d{4}-\d{2}-\d{2}$/;
    setValidBirthDate(birthDate.match(birthDateRegex) !== null);
    return birthDate.match(birthDateRegex) !== null;
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const checkPassword = (password: string): boolean => {
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d#@$!%*?&]{8,}$/;
    setValidPassword(password.match(passwordRegex) !== null);
    console.log(password.match(passwordRegex) !== null);
    console.log(password);
    if (password.match(passwordRegex) === null) {
      setPasswordErrorMessages([]);
      if (password.length < 8) {
        setPasswordErrorMessages((errors) => [
          ...errors,
          "La contraseña debe tener al menos 8 caracteres",
        ]);
      }
      if (password.match(/[a-z]/) === null) {
        setPasswordErrorMessages((errors) => [
          ...errors,
          "La contraseña debe tener al menos una letra minúscula",
        ]);
      }
      if (password.match(/[A-Z]/) === null) {
        setPasswordErrorMessages((errors) => [
          ...errors,
          "La contraseña debe tener al menos una letra mayúscula",
        ]);
      }
      if (password.match(/[0-9]/) === null) {
        setPasswordErrorMessages((errors) => [
          ...errors,
          "La contraseña debe tener al menos un número",
        ]);
      }
      if (password.match(/[\W]/) === null) {
        setPasswordErrorMessages((errors) => [
          ...errors,
          "La contraseña debe tener al menos un símbolo",
        ]);
      }
    }
    return password.match(passwordRegex) !== null;
  };

  const checkAllValid = (): boolean => {
    const checkValidEmail = checkEmail(email);
    const checkValidRut = checkRut(rut);
    const checkValidName = checkName(name);
    const checkValidLastName = checkLastName(lastName);
    const checkValidPhone = checkPhone(phone);
    const checkValidAddress = checkAddress(address);
    const checkValidBirthDate = checkBirthDate(birthDate);
    const checkValidPassword = checkPassword(password);
    const valid =
      checkValidEmail &&
      checkValidRut &&
      checkValidName &&
      checkValidLastName &&
      checkValidPhone &&
      checkValidAddress &&
      checkValidBirthDate &&
      checkValidPassword;
    return valid;
  };

  const handleRegister = async () => {
    setSubmitButtonClicked(true);
    setErrorMessage("");

    if (!checkAllValid()) {
      setSubmitButtonClicked(false);
      return;
    }

    const attributes = {
      email: email,
      "custom:RUT": rut.replace(/\./g, "").replace(/-/g, "").replace(/k/g, "0"),
      given_name: name,
      family_name: lastName,
      phone_number: phone.replace(/\s/g, ""),
      address: address,
      birthdate: birthDate,
    };

    try {
      const signUpResponse = await Auth.signUp({
        username: email,
        password,
        attributes,
      });
      console.log(signUpResponse);
      setSubmitButtonClicked(false);
      setStage(2);
    } catch (error: any) {
      let errMessage = error.message || JSON.stringify(error);
      console.log(errMessage);
      setErrorMessage(errMessage);
      setSubmitButtonClicked(false);
    }
  };

  return (
    <div className="register-container">
      <h2>Register</h2>
      {stage === 1 && (
        <div>
          {/* <!-- Error Message --> */}
          {errorMessage && (
            <div className="error-message-container">
              <h5>{errorMessage}</h5>
            </div>
          )}
          <form>
            {/* <!-- Email input --> */}
            <div className="form-outline mb-4">
              <input
                type="email"
                id="form2Example1"
                className="form-control"
                value={email}
                onChange={handleEmailChange}
              />
              <label className="form-label" htmlFor="form2Example1">
                Email address
              </label>
              {!validEmail && (
                <div className="alert alert-danger" role="alert">
                  please enter a valid email
                </div>
              )}
            </div>

            {/* <!-- RUT input --> */}
            <div className="form-outline mb-4">
              <input
                type="text"
                id="form2Example1"
                className="form-control"
                value={rut}
                onChange={handleRutChange}
              />
              <label className="form-label" htmlFor="form2Example1">
                RUT
              </label>
              {!validRut && (
                <div className="alert alert-danger" role="alert">
                  please enter a valid RUT
                </div>
              )}
            </div>

            {/* <!-- Name input --> */}
            <div className="form-outline mb-4">
              <input
                type="text"
                id="form2Example1"
                className="form-control"
                value={name}
                onChange={handleNameChange}
              />
              <label className="form-label" htmlFor="form2Example1">
                First Name
              </label>
              {!validName && (
                <div className="alert alert-danger" role="alert">
                  Name is required
                </div>
              )}
            </div>

            {/* <!-- Last name input --> */}
            <div className="form-outline mb-4">
              <input
                type="text"
                id="form2Example1"
                className="form-control"
                value={lastName}
                onChange={handleLastNameChange}
              />
              <label className="form-label" htmlFor="form2Example1">
                Last name
              </label>
              {!validLastName && (
                <div className="alert alert-danger" role="alert">
                  Last name is required
                </div>
              )}
            </div>

            {/* <!-- Phone input --> */}
            <div className="form-outline mb-4">
              <input
                type="text"
                id="form2Example1"
                className="form-control"
                value={phone}
                onChange={handlePhoneChange}
              />
              <label className="form-label" htmlFor="form2Example1">
                Phone
              </label>
              {!validPhone && (
                <div className="alert alert-danger" role="alert">
                  please enter a valid phone number
                </div>
              )}
            </div>

            {/* <!-- Address input --> */}
            <div className="form-outline mb-4">
              <input
                type="text"
                id="form2Example1"
                className="form-control"
                value={address}
                onChange={handleAddressChange}
              />
              <label className="form-label" htmlFor="form2Example1">
                Address
              </label>
              {!validAddress && (
                <div className="alert alert-danger" role="alert">
                  Address is required
                </div>
              )}
            </div>

            {/* <!-- BirthDate input --> */}
            <div className="form-outline mb-4">
              <input
                type="date"
                id="form2Example1"
                className="form-control"
                value={birthDate}
                onChange={handleBirthDateChange}
              />
              <label className="form-label" htmlFor="form2Example1">
                BirthDate
              </label>
              {!validBirthDate && (
                <div className="alert alert-danger" role="alert">
                  BirthDate is required
                </div>
              )}
            </div>

            {/* <!-- Password input --> */}
            <div className="form-outline mb-4">
              <input
                type="password"
                id="form2Example2"
                className="form-control"
                value={password}
                onChange={handlePasswordChange}
              />
              <label className="form-label" htmlFor="form2Example2">
                Password
              </label>
              {!validPassword && (
                <div className="alert alert-danger" role="alert">
                  {passwordErrorMessages.join(", ")}
                </div>
              )}
            </div>

            {/* <!-- Submit button --> */}
            {submitButtonClicked ? (
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              <button
                type="button"
                className="btn btn-primary btn-block mb-4"
                onClick={handleRegister}
              >
                Register
              </button>
            )}
          </form>
          {/* <!-- Register buttons --> */}
          <div className="text-center">
            <p>
              Already registered? &nbsp;
              <NavLink to="/login">Sign In</NavLink>
            </p>
          </div>
        </div>
      )}
      {stage === 2 && <ConfirmAccount email={email} password={password} />}
    </div>
  );
};

export default Register;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";

import "../../styles/LoginPage/ForgotPassword.css";

const ForgotPassword: React.FC = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>("");
  const [stage, setStage] = useState<number>(1); // 1 = email stage, 2 = code stage, 3 = password stage
  const [code, setCode] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  // handle return to homepage button
  const handleReturnToHomepage = (): void => {
    console.log("Return to Homepage");
    navigate("/");
  };

  // handle send email button
  const handleSendEmail = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    console.log(`Send Email to ${email}`);
    try {
      const response = await Auth.forgotPassword(email)
      console.log(response)
      setStage(2);
    } catch (error: any) {
      const errMessage = error.message || "An error occurred. Please try again.";
      console.error(errMessage);
      setErrorMessage(errMessage);
    }  
  };

  // handle reset password button
  const resetPassword = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();

    if (password !== confirmPassword) {
      console.error("Passwords are not the same");
      setErrorMessage("Passwords are not the same");
      return;
    }

    try{
      await Auth.forgotPasswordSubmit(email, code, password)
      setStage(3);
    } catch (error: any) {
      const errMessage = error.message || "An error occurred. Please try again.";
      console.error(errMessage);
      setErrorMessage(errMessage);
    }
  };

  return (
    <div className="forgot-password-container">
      <img src="/images/LoginPage/logo.png" alt="Logo" className="forgot-password-logo" />
      {stage === 1 && (
        <div>
          <h1>Forgot Password</h1>
          {errorMessage && (
            <div className="alert alert-danger" role="alert">
              {errorMessage}
            </div>
          )}
          <p>
            Enter your email address and we will send you a code to reset your password.
          </p>
          <form onSubmit={handleSendEmail}>
            <div className="form-group">
              <label htmlFor="email">Email Address:</label>
              <input
                type="email"
                className="form-control"
                id="email"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <br/>
              <button
                type="submit"
                className="btn btn-primary"
              >
                Send Reset Code
              </button>

              <button
                type="button"
                className="btn btn-secondary"
                onClick={handleReturnToHomepage}
              >
                Return to Homepage
              </button>

            </div>
          </form>
        </div>
      )}
      {stage === 2 && (
        <div>
          <h1>Reset Password Request Received</h1>
          {errorMessage && (
            <div className="alert alert-danger" role="alert">
              {errorMessage}
            </div>
          )}

          <p>
            We have received your request to reset your password. Please check your email ({email}) inbox for the code needed to reset your password.
          </p>

          <p>
            If you do not receive an email within a few minutes, please check your spam folder.
          </p>

          <form onSubmit={resetPassword}>
            <div className="form-group">
              <label htmlFor="code">Reset Code:</label>
              <input
                type="text"
                className="form-control"
                id="code"
                placeholder="Enter reset code"
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
              <br/>
              <label htmlFor="password">New Password:</label>
              <input
                type="password"
                className="form-control"
                id="password"
                placeholder="Enter new password"
                value={password}
                onChange={(e) => setPassword(e.target.value)} 
              />
              <br/>
              <label htmlFor="confirmPassword">Confirm New Password:</label>
              <input
                type="password"
                className="form-control"
                id="confirmPassword"
                placeholder="Confirm new password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <br/>
              <button
                type="submit"
                className="btn btn-primary"
              >
                Reset Password
              </button>
              <button 
                className="btn btn-secondary" 
                type="button" 
                onClick={handleReturnToHomepage}
              >
                Return to Homepage
              </button>
            </div>
          </form>
        </div>
      )}
      {stage === 3 && (
        <div>
          <h1>Password Reset Successful</h1>
          <p>
            Your password has been successfully reset. You may now login with your new password.
          </p>
          <button
            className="btn btn-primary"
            onClick={handleReturnToHomepage}
          >
            Return to Homepage
          </button>
        </div>
      )}
    </div>
  );
};

export default ForgotPassword;

import { Action } from "../actions";
import { ActionTypes } from "../action-types";

interface IState {
  loginModalOpen: boolean;
}

const initialState: IState = {
  loginModalOpen: false,
};

const reducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case ActionTypes.LOG_IN_MODAL_OPEN:
      return {
        ...state,
        loginModalOpen: true,
      };
    case ActionTypes.LOG_IN_MODAL_CLOSE:
      return {
        ...state,
        loginModalOpen: false,
      };
    default:
      return state;
  }
};

export default reducer;

import React from "react";

import "../../styles/HomePage/index.css";
import LandingPage from "./LandingPage";
import Intro from "./static/Intro";
import Projects from "./Projects";
import ProductDescription from "./static/ProductDescription";
import OurProduct from "./OurProduct";
import LogoAndInfo from "./static/LogoAndInfo";
import FinalSlogan from "./static/FinalSlogan";
import Footer from "../Footer";

const HomePage: React.FC = () => {
  return (
    <>
      <div className="home-page__scroll-down-content">
        <LandingPage />
        <Intro />
        <ProductDescription />
        {/* <OurProduct /> */}
        <Projects />
        {/* <LogoAndInfo /> */}
        <FinalSlogan />
      </div>
      <Footer />
    </>
  );
};

export default HomePage;

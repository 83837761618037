import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "@reduxjs/toolkit";
import { Auth } from "aws-amplify";

import "../../styles/Navbar/index.css";
import { actionCreators, State } from "../../state";

const NavBar: React.FC = () => {
  const loggedInState = useSelector((state: State) => state.loggedInUser);
  const dispatch = useDispatch();
  const { logOutUser, openLogInModal } = bindActionCreators(
    actionCreators,
    dispatch
  );
  const [show, setShow] = useState(true);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [lastScrollY, setLastScrollY] = useState(0);

  const handleLogOut = (): void => {
    Auth.signOut();
    logOutUser();
    closeMenu();
  };

  const controlNavbar = () => {
    if (typeof window !== "undefined") {
      if (window.scrollY > lastScrollY) {
        // if scroll down hide the navbar
        setShow(false);
      } else {
        // if scroll up show the navbar
        setShow(true);
      }

      // remember current page location to use in the next move
      setLastScrollY(window.scrollY);
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", controlNavbar);

      // cleanup function
      return () => {
        window.removeEventListener("scroll", controlNavbar);
      };
    }
  }, [lastScrollY]);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize, false);
  }, []);

  useEffect(() => {
    // on click outside of navbar collapse menu
    window.addEventListener("click", function (e) {
      const navbarCollapse = document.querySelector(".navbar-collapse");
      if (e.target !== navbarCollapse) {
        closeMenu();
      }
    });
  }, []);

  const handleLoginClick = () => {
    openLogInModal();
    closeMenu();
  };

  const closeMenu = () => {
    const navbarCollapse = document.querySelector(".navbar-collapse");
    if (navbarCollapse) {
      navbarCollapse.classList.remove("show");
    }
  };

  return (
    <nav
      className={`navbar navbar-expand-lg navbar-light bg-light navbar__show ${
        !show && "navbar__hidden"
      }`}
    >
      <div className="navbar__container container-fluid">
        <NavLink to="/">
          {/*change image with screen size*/}
          {windowWidth > 991 ? (
            <img src="images/NavBar/logo.png" alt="" className="navbar__logo" />
          ) : (
            <img
              src="images/NavBar/logo2.png"
              alt=""
              className="navbar__logo"
            />
          )}
        </NavLink>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarText"
          aria-controls="navbarText"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarText">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0 navbar__horizontal-ul">
            {loggedInState.loggedIn && (
              <li className="navbar__item nav-item">
                <NavLink to="/product" className="navbar__item__link">
                  Producto
                </NavLink>
              </li>
            )}
            <li className="navbar__item nav-item">
              {loggedInState.loggedIn ? (
                <button
                  className="login-link navbar__item__button"
                  onClick={handleLogOut}
                >
                  Cerrar Sesión
                </button>
              ) : (
                <button
                  className="login-link navbar__item__button"
                  onClick={handleLoginClick}
                >
                  Iniciar Sesión
                </button>
                // <NavLink className="login-link" to="/login" onClick={openLogInModal}>
                //   Iniciar Sesión
                // </NavLink>
              )}
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;

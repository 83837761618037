import React, { useEffect, useState } from "react";
import Viewer from "react-viewer";
import "../../styles/ProductPage/ProductImages.css";

type ProductImagesProps = {
  images: string[];
};

const ProductImages: React.FC<ProductImagesProps> = (props) => {
  const [visible, setVisible] = React.useState(false);
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  let images = props.images.map((image, index) => {
    return {
      src: image,
      alt: `Image ${index + 1}`,
    };
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleMaskClick = (e: any) => {
    if (e.target.className === "react-viewer-canvas") {
      setVisible(false);
    }
  };

  const handleOpenImage = (index: number) => {
    setActiveIndex(index);
    setVisible(true);
  };

  return (
    <div className="product-page__product-images__container">
      {windowWidth > 991 ? (
        <div>
          <div
            id={`product-page__product-images__image-big`}
            className="product-page__product-images__image"
          >
            <img
              src={images[0].src}
              alt="product"
              onClick={() => handleOpenImage(0)}
            />
          </div>
          <div className="product-page__product-images__wrapper">
            {images.slice(1).map((image, index) => {
              return (
                <div
                  key={index}
                  id={`product-page__product-images__image-${index}`}
                  className="product-page__product-images__image"
                >
                  <img
                    src={image.src}
                    alt="product"
                    onClick={() => handleOpenImage(index + 1)}
                  />
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div className="product-page__product-images__wrapper">
          <div>
            {images
              .filter((item, index) => index < 4)
              .map((image, index) => {
                return (
                  <div
                    key={index}
                    id={`product-page__product-images__image-${index}`}
                    className="product-page__product-images__image-mobile"
                  >
                    <img
                      src={image.src}
                      alt="product"
                      onClick={() => handleOpenImage(index)}
                    />
                  </div>
                );
              })}
            </div>
        </div>
      )}
      <Viewer
        activeIndex={activeIndex}
        loop={true}
        noToolbar={true}
        changeable={true}
        drag={false}
        visible={visible}
        onClose={() => {
          setVisible(false);
        }}
        images={images}
        onMaskClick={(e) => {
          handleMaskClick(e);
        }}
      />
    </div>
  );
};

export default ProductImages;

import React from "react";
import { Route, Routes } from "react-router-dom";

import HomePage from "../HomePage";
import ProductPage from "../ProductPage";
import LoginPage from "../LoginPage";
import ForgotPassword from "../LoginPage/ForgotPassword";
import Register from "../LoginPage/Register";
import "../../styles/Body/index.css";

const Body: React.FC = () => {
  return (
    <div className="body-container">
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/product/:productId" element={<ProductPage />} />
        <Route path="/product" element={<ProductPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/login/forgot-password" element={<ForgotPassword />} />
        <Route path="/login/register" element={<Register />} />
      </Routes>
    </div>
  );
};

export default Body;

import { Action } from "../actions";
import { ActionTypes } from "../action-types";

interface IState {
  loggedIn: boolean;
  user?: Object;
}

const initialState: IState = {
  loggedIn: false,
  user: undefined,
};

const reducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case ActionTypes.LOG_IN:
      return {
        ...state,
        loggedIn: true,
        user: action.payload.user,
      };
    case ActionTypes.LOG_OUT:
      return {
        ...state,
        loggedIn: false,
        user: undefined,
      };
    case ActionTypes.SET_USER:
      return {
        ...state,
        user: action.payload.user,
      };
    default:
      return state;
  }
};

export default reducer;

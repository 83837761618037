import React from "react";

import "../../styles/ProductPage/Product.css";
import ProductImages from "./ProductImages";
import ProductInfo from "./ProductInfo";

// product props
type ProductProps = {
  id: string;
  name: string;
  color: string;
  images: string[];
};

const Product: React.FC<ProductProps> = (props) => {
  return (
    <div
      className="product-page__product__container"
    >
      {/* <h1 className="product-page__product__title"><b>Elige tu hogar y conoce la mejor manera de obtenerlo.</b></h1> */}
      {/* <h2 className="product-page__product__subtitle">¿Buscas tu primer hogar?</h2> */}
      <div
        className="product-page__product__wrap"
      >
        <div className="product-page__product__images">
          <ProductImages images={props.images} />
        </div>
        <div className="product-page__product__info">
          <ProductInfo color={props.color} />
        </div>
      </div>
    </div>
  );
};

export default Product;

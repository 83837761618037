import React, { useState } from "react";

import { useDispatch } from "react-redux";
import { bindActionCreators } from "@reduxjs/toolkit";

import { actionCreators } from "../../state";
import HandleLogInUser from "./utils/HandleLogInUser";
import "../../styles/LoginModal/ConfirmAccount.css";
import { Auth } from "aws-amplify";

interface Props {
  email: string;
  password: string;
}

const ConfirmAccount: React.FC<Props> = ({ email, password }) => {
  const dispatch = useDispatch();
  const { logInUser, setGlobalUserName, closeLogInModal } = bindActionCreators(
    actionCreators,
    dispatch
  );

  const [submitButtonClicked, setSubmitButtonClicked] =
    useState<boolean>(false);
  const [confirmationCode, setConfirmationCode] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const handleConfirmationCodeChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setConfirmationCode(formatConfirmationCode(e.target.value));
  };

  const formatConfirmationCode = (confirmationCode: string): string => {
    // just numbers
    const newConfirmationCode = confirmationCode.replace(/\D/g, "");
    if (newConfirmationCode.length === 0) {
      return "";
    }
    if (newConfirmationCode.length > 6) {
      return formatConfirmationCode(newConfirmationCode.substr(0, 6));
    }
    return newConfirmationCode;
  };

  const handleConfirmationCodeSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault();
    setSubmitButtonClicked(true);
    console.log("confirmationCode: ", confirmationCode);
    if (confirmationCode.length === 6) {
      console.log("confirmationCode: ", confirmationCode);

      try {
        await Auth.confirmSignUp(email, confirmationCode);
        HandleLogInUser(
          email,
          password,
          logInUser,
          setGlobalUserName,
          function (loginResult: string) {
            setSubmitButtonClicked(false);
            if (loginResult === "SUCCESS") {
              closeLogInModal();
            }
          }
        );
      } catch (error: any) {
        setErrorMessage(error.message);
        setSubmitButtonClicked(false);
        setConfirmationCode("");
      }
    } else {
      setErrorMessage("Confirmation code must be 6 digits.");
      setSubmitButtonClicked(false);
    }
  };

  return (
    <div>
      <h1>Confirme su Email</h1>
      <p>
        Verifique la bandeja de entrada de su correo electrónico ({email}) para
        obtener el código necesario para validar su cuenta.
      </p>

      <p>
        Si no recibe un correo electrónico en unos minutos, verifique su carpeta
        de correo no deseado.
      </p>
      {errorMessage.length > 0 && (
        <div className="alert alert-danger" role="alert">
          {errorMessage}
        </div>
      )}
      <form onSubmit={handleConfirmationCodeSubmit}>
        <div className="form-outline mb-4">
          <input
            type="text"
            id="form2Example1"
            className="form-control"
            value={confirmationCode}
            onChange={handleConfirmationCodeChange}
            placeholder="Codigo de Confirmación"
          />
        </div>
        {/* <!-- Submit button --> */}
        {submitButtonClicked ? (
          <div className="spinner-border" role="status">
            <span className="sr-only">Cargando...</span>
          </div>
        ) : (
          <button
            type="submit"
            className="btn btn-primary btn-block mb-4 login-modal__confirm-account__button"
          >
            Confirmar
          </button>
        )}
      </form>
    </div>
  );
};

export default ConfirmAccount;

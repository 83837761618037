import { useState } from "react";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "@reduxjs/toolkit";
import { actionCreators } from "../../state";
import { useSelector } from "react-redux";
import { State } from "../../state";

const useModal = () => {
  const loginModal = useSelector((state: State) => state.loginModal);

  const dispatch = useDispatch();
  const { openLogInModal, closeLogInModal } = bindActionCreators(
    actionCreators,
    dispatch
  );

  const toggle = () => {
    if (loginModal.loginModalOpen) closeLogInModal();
    else openLogInModal();
  };

  return {
    isOpen: loginModal.loginModalOpen,
    toggle,
  };
};

export default useModal;

import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Modal from "../Utils/Modal";
import useModal from "../Utils/useModal";
import LoginPage from "./LoginPage";
import Register from "./Register";

import "../../styles/LoginModal/index.css";

const LoginModal = () => {

  const [value, setValue] = useState("1");
  const { isOpen, toggle } = useModal();
  const [stage, setStage] = useState<number>(1); // 1: log in, 2: confirm user, 3: forgot password

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box style={{ color: "blue" }}>
            <TabList
              className="login-modal__tablist"
              onChange={handleChange}
              textColor="inherit"
              indicatorColor="primary"
              aria-label="secondary tabs example"
              centered
            >
              <Tab label="Inicia Sesión" value="1" onClick={() => setStage(1)} />
              <Tab label="Regístrate" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <div className="login-modal__container">
              <div className="login-modal__form__input">
                <LoginPage stage={stage} setStage={setStage} />
              </div>
            </div>
          </TabPanel>
          <TabPanel value="2">
            <div className="login-modal__container">
              <div className="signIn-modal__form__input">
                <Register />
              </div>
            </div>
          </TabPanel>
        </TabContext>
      </Box>
    </Modal>
  );
};

export default LoginModal;
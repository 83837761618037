import React, { useState } from "react";

import { NavLink } from "react-router-dom";

import "../../styles/LoginModal/Register.css";
// import Pool from "./utils/UserPool";
import ConfirmAccount from "./ConfirmAccount";
// import { CognitoUserAttribute } from "amazon-cognito-identity-js";
import { Auth } from "aws-amplify";

const Register: React.FC = () => {
  const [stage, setStage] = useState<number>(1); // 1: register, 2: confirm email
  const [submitButtonClicked, setSubmitButtonClicked] =
    useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [rut, setRut] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [validEmail, setValidEmail] = useState<boolean>(true);
  const [validRut, setValidRut] = useState<boolean>(true);
  const [validPassword, setValidPassword] = useState<boolean>(true);
  const [validConfirmPassword, setValidConfirmPassword] =
    useState<boolean>(true);
  const [passwordErrorMessages, setPasswordErrorMessages] = useState<string[]>(
    []
  );
  const [errorMessage, setErrorMessage] = useState<string>("");

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const checkEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setValidEmail(emailRegex.test(email));
    return emailRegex.test(email);
  };

  const formatRut = (rut: string): string => {
    // XX.XXX.XXX-X || X.XXX.XXX-X

    const newRut = rut
      .replace(/\./g, "")
      .replace(/\-/g, "")
      .trim()
      .toLowerCase();
    if (newRut.length === 0) {
      return "";
    }
    if (newRut.length > 9) {
      return formatRut(newRut.substr(0, 9));
    }
    const lastDigit = newRut.substr(-1, 1);
    if (lastDigit.match(/[k0-9]/) === null) {
      return formatRut(newRut.substr(0, newRut.length - 1));
    }
    const rutDigit = newRut.substr(0, newRut.length - 1).replace(/\k/, "");
    let format = "";
    for (let i = 1; i <= rutDigit.length; i++) {
      if ((i - 1) % 3 === 0 && i !== 1) {
        format = ".".concat(format);
      }
      const e = rutDigit.charAt(rutDigit.length - i);
      format = e.concat(format);
    }
    return format.concat("-").concat(lastDigit);
  };

  const handleRutChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRut(formatRut(e.target.value));
  };

  const checkRut = (rut: string): boolean => {
    // XX.XXX.XXX-X || X.XXX.XXX-X
    const rutRegex = /^(\d{1,2}(\.\d{1,3}){2}-[\dkK])$/;
    setValidRut(rut.match(rutRegex) !== null);
    return rut.match(rutRegex) !== null;
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const checkPassword = (password: string): boolean => {
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d#@$!%*?&]{8,}$/;
    setValidPassword(password.match(passwordRegex) !== null);
    console.log(password.match(passwordRegex) !== null);
    console.log(password);
    if (password.match(passwordRegex) === null) {
      setPasswordErrorMessages([]);
      if (password.length < 8) {
        setPasswordErrorMessages((errors) => [
          ...errors,
          "La contraseña debe tener al menos 8 caracteres",
        ]);
      }
      if (password.match(/[a-z]/) === null) {
        setPasswordErrorMessages((errors) => [
          ...errors,
          "La contraseña debe tener al menos una letra minúscula",
        ]);
      }
      if (password.match(/[A-Z]/) === null) {
        setPasswordErrorMessages((errors) => [
          ...errors,
          "La contraseña debe tener al menos una letra mayúscula",
        ]);
      }
      if (password.match(/[0-9]/) === null) {
        setPasswordErrorMessages((errors) => [
          ...errors,
          "La contraseña debe tener al menos un número",
        ]);
      }
      if (password.match(/[\W]/) === null) {
        setPasswordErrorMessages((errors) => [
          ...errors,
          "La contraseña debe tener al menos un símbolo",
        ]);
      }
    }
    return password.match(passwordRegex) !== null;
  };

  const handleConfirmPasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setConfirmPassword(e.target.value);
  };

  const checkConfirmPassword = (
    confirmPassword: string,
    password: string
  ): boolean => {
    setValidConfirmPassword(confirmPassword === password);
    return confirmPassword === password;
  };

  const checkAllValid = (): boolean => {
    const checkValidEmail = checkEmail(email);
    const checkValidRut = checkRut(rut);
    const checkValidPassword = checkPassword(password);
    const checkValidConfirmPassword = checkConfirmPassword(
      confirmPassword,
      password
    );
    const valid =
      checkValidEmail &&
      checkValidRut &&
      checkValidPassword &&
      checkValidConfirmPassword;
    return valid;
  };

  const handleRegister = async () => {
    setSubmitButtonClicked(true);
    setErrorMessage("");

    if (!checkAllValid()) {
      setSubmitButtonClicked(false);
      return;
    }

    const attributes = {
      email: email,
      "custom:RUT": rut,
      given_name: name,
    };

    try {
      const signUpResponse = await Auth.signUp({
        username: email,
        password,
        attributes,
      });
      console.log(signUpResponse);
      setSubmitButtonClicked(false);
      setStage(2);
    } catch (error: any) {
      let errMessage = error.message || JSON.stringify(error);
      console.log(errMessage);
      setErrorMessage(errMessage);
      setSubmitButtonClicked(false);
    }
  };

  return (
    <div className="login-modal__register__container">
      {stage === 1 && (
        <div>
          {/* <!-- Error Message --> */}
          {errorMessage && (
            <div className="error-message-container">
              <h5>{errorMessage}</h5>
            </div>
          )}
          <form>
            {/* <!-- Email input --> */}
            <div className="form-outline mb-4">
              <input
                type="email"
                id="form2Example1"
                className="form-control"
                value={email}
                onChange={handleEmailChange}
                placeholder="Email"
              />
              {!validEmail && (
                <div className="alert alert-danger" role="alert">
                  Profavor ingresa un email valido
                </div>
              )}
            </div>

            {/* <!-- RUT input --> */}
            <div className="form-outline mb-4">
              <input
                type="text"
                id="form2Example1"
                className="form-control"
                value={rut}
                onChange={handleRutChange}
                placeholder="RUT"
              />
              {!validRut && (
                <div className="alert alert-danger" role="alert">
                  please enter a valid RUT
                </div>
              )}
            </div>

            {/* <!-- Password input --> */}
            <div className="form-outline mb-4">
              <input
                type="password"
                id="form2Example2"
                className="form-control"
                value={password}
                onChange={handlePasswordChange}
                placeholder="Contraseña"
              />
              {!validPassword && (
                <div className="alert alert-danger" role="alert">
                  {passwordErrorMessages.join(", ")}
                </div>
              )}
            </div>

            {/* <!-- Password input --> */}
            <div className="form-outline mb-4">
              <input
                type="password"
                id="form2Example2"
                className="form-control"
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                placeholder="Confirmar Contraseña"
              />
              {!validConfirmPassword && (
                <div className="alert alert-danger" role="alert">
                  La Contraseña de confirmación no coincide
                </div>
              )}
            </div>

            {/* <!-- Submit button --> */}
            {submitButtonClicked ? (
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              <button
                type="button"
                className="btn btn-primary btn-block mb-4 login-modal__register__button"
                onClick={handleRegister}
              >
                Register
              </button>
            )}
          </form>
        </div>
      )}
      {stage === 2 && <ConfirmAccount email={email} password={password} />}
    </div>
  );
};

export default Register;

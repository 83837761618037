import React, { useState } from "react";

import "../../styles/ProductPage/Filter.css";
import Price from "./Filters/Price";

const ProductPage: React.FC = () => {
  return (
    <div className="product-page__filter__container">
      <div className="product-page__filter__wrap">
        <div className="product-page__filter__option">
          <div className="dropdown">
            <button
              className="btn sh product-page__filter__button"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="fa fa-map-marker product-page__filter__icon"></i>
              Ubicación
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <Price />
            </ul>
          </div>
        </div>
        <div className="product-page__filter__option">
          <div className="dropdown">
            <button
              className="btn sh product-page__filter__button"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="fa fa-usd product-page__filter__icon"></i>
              Precio
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <Price />
            </ul>
          </div>
        </div>
        <div className="product-page__filter__option">
          <div className="dropdown">
            <button
              className="btn product-page__filter__button"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="fa fa-bed product-page__filter__icon"></i>
              Dormitorios
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <Price />
            </ul>
          </div>
        </div>
        <div className="product-page__filter__option product-page__filter__desktop">
          <div className="dropdown">
            <button
              className="btn product-page__filter__button"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="fa fa-bath product-page__filter__icon"></i>
              Baños
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <Price />
            </ul>
          </div>
        </div>
        <div className="product-page__filter__option">
          <div className="dropdown">
            <button
              className="btn product-page__filter__button"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="fa fa-plus-square-o product-page__filter__icon"></i>
              Más Filtros
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <Price />
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductPage;

import { ActionTypes } from "../action-types";
import { Dispatch } from "@reduxjs/toolkit";
import { Action } from "../actions";

export const logInUser = (user: Object) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionTypes.LOG_IN,
      payload: {
        user,
      },
    });
  };
};

export const logOutUser = () => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionTypes.LOG_OUT,
    });
    dispatch({
      type: ActionTypes.SET_NAME,
      payload: {
        name: "",
      },
    });
  };
};

export const setGlobalUser = (user: object) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionTypes.SET_USER,
      payload: {
        user,
      },
    });
  };
};

export const setGlobalUserName = (name: string) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionTypes.SET_NAME,
      payload: {
        name,
      },
    });
  };
};

export const openLogInModal = () => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionTypes.LOG_IN_MODAL_OPEN,
    });
  };
}

export const closeLogInModal = () => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionTypes.LOG_IN_MODAL_CLOSE,
    });
  };
}
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "@reduxjs/toolkit";

import "../../styles/LoginModal/LoginPage.css";
import ConfirmAccount from "./ConfirmAccount";
import ForgotPassword from "./ForgotPassword";
import { actionCreators } from "../../state";
import HandleLogInUser from "./utils/HandleLogInUser";
import { Auth } from "aws-amplify";

interface Props {
  stage: number;
  setStage: React.Dispatch<React.SetStateAction<number>>;
}

const LoginPage: React.FC<Props> = ({ stage, setStage }: Props) => {
  const dispatch = useDispatch();
  const { logInUser, setGlobalUserName, closeLogInModal } = bindActionCreators(
    actionCreators,
    dispatch
  );
  const [submitButtonClicked, setSubmitButtonClicked] =
    useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setPassword(e.target.value);
  };

  const handleLogin = async (): Promise<void> => {
    setSubmitButtonClicked(true);
    setErrorMessage("");

    await HandleLogInUser(
      email,
      password,
      logInUser,
      setGlobalUserName,
      async function (result: string) {
        console.log("result:" + result);
        setSubmitButtonClicked(false);
        if (result === "SUCCESS") {
          closeLogInModal();
        } else if (result === "User is not confirmed.") {
          console.log("handle confirm user");
          try {
            const result = await Auth.resendSignUp(email);
            console.log("result: ", result);
            setStage(2);
          } catch (error: any) {
            let errorMessage = error.message || "User is not confirmed.";
            console.log("error: ", error);
            setErrorMessage(errorMessage);
          }
        } else {
          setErrorMessage(result);
        }
      }
    );
  };

  return (
    <div className="login-modal__login__container">
      {stage === 1 && (
        <div>
          {/* <!-- Error Message --> */}
          {errorMessage && (
            <div className="login-modal__error-message__container">
              <h5>{errorMessage}</h5>
            </div>
          )}
          <form>
            {/* <!-- Email input --> */}
            <div className="form-outline mb-4">
              <input
                placeholder="Email"
                type="email"
                id="form2Example1"
                className="form-control"
                value={email}
                onChange={handleEmailChange}
              />
            </div>

            {/* <!-- Password input --> */}
            <div className="form-outline mb-4">
              <input
                placeholder="Clave"
                type="password"
                id="form2Example2"
                className="form-control"
                value={password}
                onChange={handlePasswordChange}
              />
            </div>
            <div className="col login-modal__forgot-password__container">
              {/* <!-- Simple link --> */}
              <a onClick={() => setStage(3)}>¿Has olvidado tu contraseña?</a>
            </div>
            <br />

            {/* <!-- Submit button --> */}
            {submitButtonClicked ? (
              <div className="spinner-border" role="status">
                <span className="sr-only">Cargando...</span>
              </div>
            ) : (
              <button
                type="button"
                className="btn btn-primary btn-block mb-4 login-modal__login__button"
                onClick={handleLogin}
              >
                Iniciar Sesión
              </button>
            )}

            {/* <!-- Register buttons --> */}
            <div className="text-center">
              <p>or sign in with:</p>
              <button
                type="button"
                className="btn btn-link btn-floating mx-1 login-modal__login__icon"
              >
                <i className="fa fa-facebook-f"></i>
              </button>

              <button
                type="button"
                className="btn btn-link btn-floating mx-1 login-modal__login__icon"
              >
                <i className="fa fa-google"></i>
              </button>

              <button
                type="button"
                className="btn btn-link btn-floating mx-1 login-modal__login__icon"
              >
                <i className="fa fa-twitter"></i>
              </button>

              <button
                type="button"
                className="btn btn-link btn-floating mx-1 login-modal__login__icon"
              >
                <i className="fa fa-github"></i>
              </button>
            </div>
          </form>
        </div>
      )}
      {stage === 2 && <ConfirmAccount email={email} password={password} />}
      {stage === 3 && <ForgotPassword setStage={setStage} />}
    </div>
  );
};

export default LoginPage;

import React, { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "@reduxjs/toolkit";
import { Auth } from "aws-amplify";

import "./App.css";
import { State, actionCreators } from "./state";
import Navbar from "./components/Navbar";
import Body from "./components/Body/index";
import Footer from "./components/Footer/index";
import LoadingPage from "./components/LoadingPage/index";
import LoginModal from "./components/LoginModal/index";
import { log } from "console";

function App() {
  const dispatch = useDispatch();
  const { logOutUser, setGlobalUser, setGlobalUserName } = bindActionCreators(
    actionCreators,
    dispatch
  );

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const cognitoUser = await Auth.currentAuthenticatedUser();
        const currentSession = cognitoUser.signInUserSession;
        cognitoUser.refreshSession(
          currentSession.refreshToken,
          (err: any, session: any) => {
            console.log("session");
            console.log(session);
            console.log("congitoUser2");
            console.log(cognitoUser);
            setGlobalUser(cognitoUser);
            setLoading(false);
          }
        );
      } catch (e) {
        console.log(e);
        logOutUser();
        setLoading(false);
      }
    })();
  }, []);

  return (
    <div className="App">
      {loading ? (
        <LoadingPage />
      ) : (
        <header className="App-header">
          <BrowserRouter>
            <LoginModal />
            <Navbar />
            <Body />
            <Footer />
          </BrowserRouter>
        </header>
      )}
    </div>
  );
}

export default App;
